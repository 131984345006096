.policy-section h1,
.policy-section h2 {
    color: #fff;
}

.policy-section h1 {
    font-size: 2.5em;
    color: #fff;
}

.policy-section h4 {
    margin-top: 30px;
    color: #fff;
}
.policy-section h5 {
    margin-top: 20px;
    color: #fff;
}

.policy-section p {
    margin-bottom: 0px;
    color: #fff;
}

.policy-section b {
    font-weight: bold;
    color: #fff;
}

.policy-section ul {
    margin-left: 20px;
    color: #fff;
}