.service-payments {
  min-height: 100vh;
  height: 100%;
  background: #2aadde;
  padding-top: 70px;
  padding-bottom: 70px;
}

.service-payments .sp-details {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden;
  /* it can be removed */
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.service-payments .sp-details .header {
  padding: 26px 0 16px;
  border-bottom: 0.5px solid #e8e8e8;
}

.service-payments .sp-details .service-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 24px;
  margin-bottom: 0;
}

.service-payments .sp-details .service-description {
  margin-top: 6px;
  color: #a7a7a7;
}

.fa-asterisk {
  color: red;
  font-size: 8px;
}

.row-bottom {
  margin-left: -7px;
  margin-right: -7px;
  padding: 20px 20px 0;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.Go-back:hover {
  cursor: pointer;
}


.price-text {
  font-size: 26px;
  font-weight: bold;
  color: var(--theme-color);
}

.payNowButton {
  border: none;
  background-color: var(--primary-button);
  color: var(--primary-button-text);
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  min-width: 200px;
  font-size: 18px;
  outline: none;
}

.payNowButton:disabled {
  opacity: 0.7;
}

.edit-link {
  cursor: pointer;
}

.alert-calendar-information {
  font-size: 14px;
}