/* General styles */
.faq-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 50px;
    background-color: var(--primary-button);
  }
  
  .faq-left {
    max-width: 40%;
  }
  
  .faq-right {
    max-width: 55%;
  }
  
  /* Left side styles */
  .faq-left h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff!important;

  }
  
  .faq-left p {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #fff!important;
  }
  
  .faq-contact-link {
    color: #f1f1f1;
    font-weight: bold;
    text-decoration: none;
  }
  
  .faq-contact-link:hover {
    text-decoration: underline;
  }
  
  /* Right side (Accordion) styles */
  .faq-right {
    width: 100%;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #e1e1e13d;
  }
  
  .faq-question {
    font-size: 1.25rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    background-color: transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    color: #fff;
  }
  
  
  .faq-answer {
    padding: 15px;
    font-size: 1rem;
    color: #f1f1f1;
  }
  
  /* FAQ icon rotation */
  .faq-icon {
    color: #f1f1f1;
    transition: transform 0.3s ease;
  }
  
  .faq-icon.rotate {
    transform: rotate(180deg);
  }
  
  /* Responsive styles */
  
  /* Medium screens (tablets) */
  @media (max-width: 1024px) {
    .faq-section {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .faq-left {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .faq-right {
      max-width: 100%;
    }
  
    .faq-left h2 {
      font-size: 2rem;
    }
  
    .faq-left p {
      font-size: 0.9rem;
    }
  
    .faq-question {
      font-size: 1.2rem;
      padding: 10px;
    }
  
    .faq-answer {
      font-size: 0.95rem;
      padding: 10px;
    }
  }
  
  /* Small screens (mobile) */
  @media (max-width: 768px) {
    .faq-section {
      padding: 20px;
    }
  
    .faq-left h2 {
      font-size: 1.8rem;
      line-height: 1.2;
    }
  
    .faq-left p {
      font-size: 0.85rem;
      margin-bottom: 5px;
    }
  
    .faq-question {
      font-size: 1.1rem;
      padding: 8px 12px;
    }
  
    .faq-answer {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
  }
  
  /* Very small screens (small mobile) */
  @media (max-width: 480px) {
    .faq-section {
      padding: 15px;
    }
  
    .faq-left h2 {
      font-size: 1.5rem;
    }
  
    .faq-left p {
      font-size: 0.8rem;
    }
  
    .faq-question {
      font-size: 1rem;
      padding: 8px;
    }
  
    .faq-answer {
      font-size: 0.85rem;
      padding: 8px;
    }
  }
  