.policy_section h1,
.policy_section h2 {
    color: #fff;
}

.policy_section h1 {
    font-size: 2.5em;
    color: #fff;
}

.policy_section h4 {
    margin-top: 30px;
    color: #fff;
}
.policy_section h5 {
    margin-top: 20px;
    color: #fff;
}

.policy_section p {
    margin-bottom: 0px;
    color: #fff;
}

.policy_section ul li {
    color: #fff;
}

.policy_section b {
    font-weight: bold;
    color: #fff;
}

.policy_section ul {
    margin-left: 20px;
    color: #fff;
}