.booking-confirmation {
  height: 100%;
  padding-bottom: 20px;
}

.booking-confirmation .booking-details {
  overflow: hidden;
  padding-bottom: 10px;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.details .booking-success-icon {
  /* margin: 5rem; */
  text-align: center;

}

.booking-success-icon img {
  width: 300px;
  height: 300px;
}

.meeting-info p {
  margin-top: 30px;
  font-style: italic;
}

.booking-title {
  font-size: 14px;
  color: grey;
  font-weight: bold;
  padding: 5px 0px;
}

.booking-value {
  font-size: 16px;
  color: black;
  font-weight: bold;
  padding: 5px 0px;
}

.payment-status-failed {
  font-size: 15px;
  color: red !important;
  background-color: rgba(255, 0, 0, 0.112);
  border-radius: 18px;
  text-transform: capitalize;
  padding: 10px 15px;
  font-weight: bold;
}


.meeting-link {
  width: 50%;
}

@media (max-width: 768px) {
  .booking-success-icon img {
    width: 250px;
    height: 250px;
  }

  .booking-confirmation .booking-details {
    padding-bottom: 10px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .payment-status-failed {
    padding: 7px 15px;
  }
}


@media (max-width: 480px) {
  .booking-success-icon img {
    width: 200px;
    height: 200px;
  }

  .booking-title {
    font-size: 12px;
    color: grey;
    font-weight: bold;
    padding: 5px 0px;
  }

  .service-title {
    font-size: 14px;
    opacity: 0.8;
    font-style: italic;
  }

  .booking-value {
    font-size: 14px;
    color: black;
    font-weight: bold;
    padding: 5px 0px;
  }

  .meeting-link {
    width: 60%;
  }

  .meeting-info p {
    font-size: 14px;
    margin-top: 15px;
    font-style: italic;
  }

  .payment-status-failed {
    padding: 5px 15px;
  }

}