.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.card .skeleton-text {
  width: 100%;
  height: 0.6rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
.header-buttons {
  margin-left: 4rem;
}
.services .header-buttons .active-filter-button {
  background-color: grey;
}
.service-list .service-card {
  border: 2px solid rgb(166, 165, 165);
  width: 25rem !important;
  margin-bottom: 2rem !important;
}
.service-list .card {
  align-items: normal !important;
}
.edit-delete .edit-link {
  margin: 0px;
  padding-right: 10px;
}
.edit-delete .delete-link {
  margin: 0px;
  padding-left: 10px;
}
.edit-delete .edit-link:hover,
.edit-delete .delete-link:hover {
  cursor: pointer;
  font-size: larger;
}

/* ----------- toogle button css -------------- */
.slider {
  background-color: #ffffff2b;
  border-radius: 100px;
  padding: 1px;
  margin: 10px;
  cursor: pointer;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  align-items: center;
  position: relative;
  display: inline-block;
  width: 51px;
  height: 29px;
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
    rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset, #22cc3f 0px 0px 0px 0px inset,
    rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.slider::after {
  content: "";
  display: flex;
  top: 2.3px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #e3e3e3;
  border-radius: 200px;
  position: absolute;
  box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
  transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  will-change: left, background-color;
}

.switch input[type="checkbox"]:checked + .slider {
  box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset,
    #22cc3f 0px 0px 0px 2px inset, #22cc3f 0px 0px 0px 24px inset,
    rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.switch input[type="checkbox"]:checked + .slider::after {
  left: 24px;
}

.switch input[type="checkbox"] {
  display: none;
}
