.profile {
  padding: 10px;
}
.header-title {
  padding-left: 10px;
  margin-left: 50px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.header-title > p {
  margin-bottom: 0px;
}
.main {
  margin-left: 50px;
  padding: 10px;
}