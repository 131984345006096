.main-container {
  background-color: rgb(234, 221, 234);
}
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.facebook {
  background-color: #3b5998;
}
.twitter {
  background-color: #55acee;
}

.input-container {
  border: 2px solid burlywood;
  border-radius: 25px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.bordercustomround{
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 35px;
  width: 43%;
}

.bgcwhitecustom{
  background-color: #fff;
}

.width48pccustom{
  width: 48%;
}

.martop1remcustom{
  margin-top: 1rem;
}