body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

.main-content-title {
  height: 800px;
}

.company-title .navbar-brand {
  font-size: xx-large;
}

/*-----------------navbar section css -----------------------*/
.categories-homepage-btn {
  border: none;
  margin-top: 2px;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 80vh;
  background: #fff;
}

#hero .container {
  /* padding-top: 72px; */
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #171717;
}

#hero h2 {
  color: rgba(8, 7, 7, 0.6);
  margin-bottom: 50px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}

#hero .btn-get-started:hover {
  background: #209dd8;
}

#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: #47b2e4;
}

/* animation is stoped for homepage for now */

/* #hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
} */


@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }

  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*===============DropDown=========*/
.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

.dropdown-hover:hover>.dropdown-menu {
  display: inline-block;
}

.dropdown-hover>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: var(--theme-color);
}

.section-title {
  text-align: center;
  padding-bottom: 10px;
}

.section-title h2 {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: white;
}

/* .section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
} */

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li+li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #47b2e4;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.services .icon-box .icon {
  margin-bottom: 10px;
}

.services .icon-box .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  transform: translateY(-10px);
}

.services .icon-box:hover h4 a {
  color: #47b2e4;
}


#dashboard .carousel-control-prev,
.carousel-control-next {
  display: none;
}


.headingDashboard {
  font-size: 54px;
}

.subheadingDashboard {
  font-size: 20px;
}

.dashboardImage {
  width: 550px;
  height: auto;
}

.dashboardImageFeature {
  width: 550px;
  height: auto;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.fadeInAnimation {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



.elementAnimation {
  opacity: 0;
  /* Ensure the element is initially hidden */
  animation: fadeInLeft 2s ease-in-out forwards;
}

.loginBtn {
  font-size: 18px;
  font-weight: bold;
  margin-top: 40px;
  width: fit-content;
  padding: 10px 20px;
  background-color: var(--primary-button);
  border-radius: 6px;
  color: white;
  text-decoration: none;
}

#howItWorkContent {
  min-height: 700px;
}

.loginBtn:hover {
  color: white;
  text-decoration: none;
}

.featuresHeading {
  font-size: 36px;
  color: white !important;
  font-weight: bold;
  padding-bottom: 0px;
}

.featuresSubHeading {
  font-size: 20px;
  color: white;
  text-align: center;
  padding-bottom: 20px;
}


.feature-header {
  margin-bottom: 30px;
}

.feature-header h1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  color: #1e1e1e;
}

.feature-header p {
  font-size: 1.2rem;
  color: #737373;
  margin-top: 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.nav {
  position: relative !important;
  background: local;
}

.nav-tabs {
  display: flex;
  justify-content: center;
  gap: 40px;
  width: 100%;
  margin: 30px 0;
  padding: 0;
  border-bottom: 0 !important;
}

.nav-tabs li {
  list-style-type: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 10px 15px;
  position: relative;
}

.nav-tabs .active-blue {
  color: #5a67d8;
  font-weight: 600;
  border-bottom: 3px solid #5a67d8;
}

.nav-tabs .active-red {
  color: #ff5454;
  font-weight: 600;
  border-bottom: 3px solid #ff5454;
}

.nav-tabs .active-green {
  color: #07f72f;
  font-weight: 600;
  border-bottom: 3px solid #07f72f;
}

.nav-tabs .active-yellow {
  color: #dddd24;
  font-weight: 600;
  border-bottom: 3px solid #dddd24;
}

.red-icon {
  color: #ff5454 !important;
}

.blue-icon {
  color: #5a67d8 !important;
}

.green-icon {
  color: #07f72f !important;
}

.yellow-icon {
  color: #dddd24 !important;
}

.feature-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.features {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: start;
  padding-left: 30px;
}

.feature-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 30px 20px;
  width: 360px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.feature-card h4 {
  font-size: 18px;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 12.5px;
  color: #737373;
}

.feature-card i {
  font-size: 2rem;
  color: #5a67d8;
  margin-bottom: 10px;
}



.cta-box {
  background-color: #f8f8f8;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  width: auto;
  max-width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 360px;
}

.cta-box h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e1e1e;
  margin-bottom: 10px;
}

.cta-box p {
  font-size: 1rem;
  color: #666;
}

.cta-button {
  background-color: #5a67d8;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.cta-redBg {
  background-color: #ff5454;
}

.cta-blueBg {
  background-color: #5a67d8 !important;
}

.cta-greenBg {
  background-color: #07f72f !important;
  color: black;
}

.cta-yellowBg {
  background-color: #dddd24 !important;
  color: black;
}


.feature-section-wrapper {
  display: flex;
  align-items: center;
}

/* Optional: You can add media queries for better responsiveness */
@media (max-width: 768px) {

  .section {
    padding: 40px 0px;
  }

  .section-title {
    padding-bottom: 0px;
  }

  .section-title h2 {
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    color: white;
    padding-bottom: 0px;
  }


  .features {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-left: 0px;
  }

  .cta-box,
  .feature-card {
    width: 100%;
    max-width: 300px;
  }

  .nav-tabs {
    gap: 5px;
  }

  .feature-section-wrapper {
    flex-direction: column-reverse;
  }

  .cta-box {
    margin-top: 20px;
    min-width: 350px;
  }

  .nav-tabs li {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 10px 5px;
    min-width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .featuresHeading {
    font-size: 20px;
    color: white !important;
    font-weight: bold;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  .featuresSubHeading {
    font-size: 14px;
    color: white;
    padding-left: 20px;
  }

  .dashboardImageFeature {
    width: 280px;
    height: auto;
  }

}

@media (max-width: 575px) {

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(0);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .headingDashboard {
    font-size: 20px;
  }

  .subheadingDashboard {
    font-size: 14px;
    padding-top: 10px;
  }

  .loginBtn {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  #hero {
    height: 70vh;
    text-align: center;
  }
}

.heading {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.categoryContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  /* Adjusts layout on smaller screens */
}

.categoryButton {
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  background-color: #ffffff;
  color: var(--theme-color);
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.activeButton {
  background-color: var(--theme-color);
  color: white;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.card {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px var(--theme-color);
  text-align: center;
}

.imagePlaceholder img {
  background-color: #e5e7eb;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 1rem;
}

.advisorName {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.advisorInfo {
  font-size: 1rem;
  color: #6b7280;
}

.noAdvisors {
  text-align: center;
  font-size: 1.2rem;
  color: #6b7280;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .heading {
    font-size: 2rem;
  }

  .gridContainer {
    grid-template-columns: 1fr 1fr;
    /* Adjust grid for smaller screens */
    gap: 1rem;
  }

  .categoryButton {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  .card {
    padding: 1rem;
    width: 150px !important;
    height: 250px !important;
  }

  .imagePlaceholder img {
    width: 80px;
    height: 80px;
  }

  .advisorName {
    font-size: 1rem;
  }

  .advisorInfo {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .gridContainer {
    grid-template-columns: 1fr;
    /* Stack cards vertically on very small screens */
  }

  .heading {
    font-size: 1.75rem;
  }

  .categoryButton {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}


/* General styles */
.integrations-container {
  min-height: 700px;
  padding: 50px 20px;
  text-align: center;
  background-color: var(--theme-color);
  color: white !important;
}

.integrations-title {
  font-size: 3rem;
  margin-bottom: 40px;
  color: #fff;
}

.integrations-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;

}

/* Text and button styling */
.integrations-text {
  max-width: 500px;
  text-align: left;
  color: #fff !important;
}

.integrations-text h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #fff;
}

.integrations-text p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #fff;
}

.connect-button {
  background-color: #0070f3;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
}

.beta-note {
  font-size: 0.875rem;
  color: white;
}

/* Icon grid */
.integrations-icons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hub-icon {
  width: 600px;
}

.app-icons-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.app-icons-grid img {
  width: 60px;
  height: 60px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .integrations-content {
    flex-direction: column;
    text-align: center;
  }

  .integrations-text {
    text-align: center;
  }

  .hub-icon {
    width: 300px;
  }

  .app-icons-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .integrations-title {
    font-size: 2rem;
  }


  .app-icons-grid img {
    width: 50px;
    height: 50px;
  }
}

/* General section styles */
.advisor-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background-color: var(--theme-color);
}

.advisor-title {
  font-size: 3rem;
  margin-bottom: 30px;
  color: var(--primary-button-text);
}

/* Form wrapper */
.advisor-form-wrapper {
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  width: 100%;
  max-width: 800px;
}

.advisor-form-wrapper h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Form and input field */
.advisor-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.advisor-form input {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 65%;
  font-size: 1rem;
  margin-right: 10px;
}

.advisor-button {
  background-color: var(--primary-button);
  color: var(--primary-button-text);
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .advisor-form {
    flex-direction: column;
  }

  .advisor-form input {
    width: 100%;
    margin-bottom: 10px;
  }

  .advisor-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .advisor-title {
    font-size: 2rem;
  }

  .advisor-form-wrapper h2 {
    font-size: 1.5rem;
  }

  .advisor-button {
    padding: 12px;
    font-size: 0.9rem;
  }

  .advisor-form input {
    padding: 12px;
    font-size: 0.9rem;
  }
}

.howItWorkVideo {
  width: 100%;
  height: 500px;
}

#feature {
  min-height: 700px;
}

#myhero {
  min-height: 700px;
}

.categoryParentBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(700px - 200px);
}

@media (max-width: 768px) {

  #howItWorkContent {
    min-height: auto;
  }

  #feature {
    min-height: fit-content;
  }

  #myhero {
    min-height: fit-content;

  }

}

@media (max-width: 480px) {

  #howItWorkContent {
    min-height: auto;
  }

  #feature {
    min-height: fit-content;
  }

  #myhero {
    min-height: fit-content;

  }
}

.dev-banner {
  background-color: #ffeb3b;
  /* Yellow background */
  color: #000;
  /* Black text */
  text-align: center;
  font-weight: bold;
  padding: 10px;
  position: relative;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
}