/* @import url("../../Utility/font/gilroy/Gilroy-SemiBold.ttf"); */


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}


body {
  margin: 0;
  font-family: 'Gilroy', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Gilroy', sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

:root {
  --primary-button: #0f3350;
  --theme-color: #072034;
  --primary-button-text: #fff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 50%;
  max-width: 600px;
  position: relative;
}

.cross-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;

}

@media (max-width: 768px) {
  .modal-content {
    background: white;
    max-width: 600px;
    width: 470px !important;
    position: relative;
    overflow-y: scroll;
  }
}

@media (max-width: 480px) {
  .modal-content {
    background: white;
    max-width: 600px;
    width: 90% !important;
    position: relative;
    overflow-y: scroll;
    max-height: 90%;
  }
}