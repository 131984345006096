.contact-section {
    background-color: var(--primary-button);
    padding: 50px 20px;
    text-align: left;
    min-height: calc(100vh - 490px);
    display: flex;
    align-items: center;
}

.contact-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
    color: white !important;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.contact-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

.contact-card {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 300px;
    position: relative;
}

.contact-content h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.contact-content p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
}

.contact-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.8rem;
    color: #000;
}

.contact-logo {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.contact-logo img {
    width: 120px;
}

/* Mobile View */
@media (max-width: 768px) {
    .contact-cards {
        flex-direction: column;
    }

    .contact-card {
        margin-bottom: 20px;
    }

    .contact-section h2 {
        font-size: 2rem;
    }

    .contact-content h3 {
        font-size: 1.1rem;
    }

    .contact-content p {
        font-size: 0.9rem;
    }
}