.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.facebook {
  background-color: #3b5998;
}
.twitter {
  background-color: #55acee;
}
.px-25custom{
  padding-right: 25%;
  padding-left: 25%;
}
.marbotcustom1rem{
  margin-bottom: 1rem;
  margin-right: 4rem;
}

.bordercustomround2{
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 35px;
}