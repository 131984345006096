.availability .selected-availability-header {
  background-color: #80b9ebb2;
}
.availability .header-buttons .btn-outline-secondary {
  --bs-btn-hover-bg: #80b9ebb2;
}
.availability .availability-main .availability-tabs .add-slots {
  border: 1px solid #f2f6f9;
  border-radius: 6px;
  margin-top: 20px;
}

.availability .availability-main .availability-tabs .add-slots .slot-item {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid #f2f6f9 !important;
  padding: 16px 24px 4px !important;
}
.availability
  .availability-main
  .availability-tabs
  .add-slots
  .slot-item
  .slot-section
  .slots-unavailable {
  margin-top: 8px;
  padding-bottom: 22px;
}
