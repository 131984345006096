.header-block {
  position: relative;
}

.fa-youtube {
  color: red;
}

.fa-twitter {
  color: skyblue;
}

.fa-linkedin {
  color: rgb(39, 38, 38);
}

.fa-video {
  color: var(--primary-button);
  ;
}

.public-profile-image img {
  width: 300px;
  height: 300px;
  position: relative;
  left: 7%;
}

.user-information {
  margin-top: 5rem;
  gap: 2rem;
}

.public-profile-section .user-social-links a {
  text-decoration: none !important;
  color: none !important;
}

/* profile-reachout-section */
.reach-out-to-me {
  background-color: var(--theme-color);
  padding: 4rem 0;
  min-height: 50vh;
}

.reaching-heading>h1 {
  font-weight: bolder;
  margin-bottom: 30px;
  color: var(--primary-button-text);
}

/* services section  */

.bsnone {
  box-shadow: none;
}

.service-card {
  width: 95%;
  height: auto;
}

.service-card-on-hover {
  background-color: black;
}

/* .right-arrow-icon{
  background-color: black;
} */

/* feedback section */
.feedback-section {
  margin-top: 6rem;
  margin-bottom: 2rem;
}

.feedback-image {
  width: 100%;
}

.actual-feedback-image {
  width: 100%;
  height: auto;
}

.feedback-profile-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.feedback-container {
  margin-top: 3rem !important;
  border-bottom: 0px !important;

}

.feedback-card {
  width: 100%;
  height: 10rem;
  margin-top: 2rem;
  min-width: 300px;
}

.About-me-heading-image h1 {
  color: var(--theme-color);
  font-weight: bold;
}

/* readmore css*/
.about-image-css {
  width: 70%;
  height: 40%;
}

/* about me section */
.about-me {
  margin-top: 4rem !important;
  margin-bottom: 2rem !important;
}


.bg-profile-header {
  background-color: var(--theme-color);
  /* background: #072034; */
  box-shadow: 0 1rem 3rem #818181b5 !important;
}

.btn-primary {
  border: none;
  background-color: var(--theme-color);
  padding-top: 11px !important;
}

.btn-primary>a {
  color: var(--primary-button-text) !important;
  text-decoration: none;
}

.public-profile-section {
  height: 150px;
  z-index: 9;
}

.profile-user-name {
  font-weight: bold;
  color: black;
  font-size: 28px;
}

.profile-details-block {
  min-height: 30vh;
}

/* .public-profile-image img{
  position: fixed;
  transition: 0.4s;
} */


.feedback-heading>h1 {
  font-weight: bold;
  color: var(--theme-color);
}

.serviceCategoryButton {
  border-radius: 0px;
  background-color: var(--theme-color);
  color: var(--primary-button-text);
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 0.5rem 1.5rem;
}

.categoryContainer {
  justify-content: start !important;
  margin: 0px 0px 30px 0px !important;
}

.see-more-less-button {
  cursor: pointer;
  color: #00aaff;
  padding-bottom: 20px;
  align-content: end;
}


.carousel-item-spacing {
  padding: 0 10px;
  /* Adjust horizontal padding for spacing */
}

/* Smooth transition for active items */
.react-multi-carousel-item--active {
  transition: transform 0.3s ease-in-out;
}

/* Feedback card adjustments */
.feedback-card-container {
  margin: 0 10px;
  /* Additional margin for spacing */
}

.video-icon img {
  width: 50px;
}


@media (max-width: 768px) {
  .card {
    width: 100% !important;
    height: fit-content !important;
  }

  .btn-primary {
    width: 50%;
  }

  .public-profile-image img {
    width: 150px;
    height: 150px;
    left: 0%;
  }

  .public-profile-section {
    height: 80px !important;
  }

  .profile-user-name {
    font-size: 24px;
    font-weight: 600;
  }

  .feedback-section {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .feedback-container {
    margin-top: 10px !important;
  }

  .feedback-heading>h1 {
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--theme-color);
  }

  .reaching-heading>h1 {
    margin-bottom: 15px;
  }

  .user-social-links {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .user-experience {
    font-size: 1rem !important;
  }

  .fa-youtube {
    color: red;
  }

  .fa-twitter {
    color: skyblue;
  }

  .fa-linkedin {
    color: white;
  }

  .carousel-item-padding-40-px {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  .react-multi-carousel-item--active {
    transform: scale(1);
  }

  .card-body {
    padding: 0px;

  }

  .feedback-card {
    min-width: 250px !important;
  }

  .feedback-card-container {
    margin: 0px 60px;
  }

  .serviceCategoryButton {
    padding: 0.5rem 1rem;
  }

  .video-icon img {
    width: 40px;
  }


}

.user-links-linkedin-icons {
  width: 45px;
  border-radius: 9px;
}

.user-links-twitter-icons {
  width: 45px;
  border-radius: 9px;
}

.user-links-youtube-icons {
  width: 45px;
  border-radius: 9px;
}

.user-links-customlink-icons {
  width: 45px;
  border-radius: 9px;
}

.user-links-instagram-icons {
  width: 45px;
  border-radius: 9px;
}

.user-links-facebook-icons {
  width: 45px;
  border-radius: 9px;
}

.bg-green-time {
  border: 3px solid #84f0ad;
  background-color: #c9fcdc6e !important;
  border-radius: 6px;
}

.bg-yellow-ruppes {
  border: 3px solid #f2ff00;
  background-color: #f4ff213d !important;
  border-radius: 6px;
}

.social-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}

.social-icons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
}



@media (max-width: 480px) {
  .serviceCategoryButton {
    padding: 0.4rem 0.8rem;
  }

  .video-icon img {
    width: 35px;
  }

}