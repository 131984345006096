.footer {
    background-color: #f9f9f9;
    padding: 40px 20px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

/* Footer brand */
.footer-left {
    flex: 1;
    margin-bottom: 0px;
}

.footer-brand {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Footer section */
.footer-right {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

/* Accordion Titles */
.footer-accordion-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}

.footer-accordion-content {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-accordion-content li,
.footer-accordion-content li a {
    margin-bottom: 8px;
    color: #333;
    text-decoration: none;
}

/* Footer bottom */
.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    font-size: 14px;
    flex-wrap: wrap;
}

.footer-language,
.footer-links ul,
.footer-social {
    display: flex;
    align-items: center;
}

.footer-language i {
    margin-right: 5px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
}

.footer-links ul li {
    color: #333;
}

.footer-social {
    gap: 15px;
}

.footer-social i {
    font-size: 18px;
    color: #333;
}

.footer-social i:hover {
    color: #007bff;
}

.footer-copyright {
    font-size: 14px;
    color: #888;
    text-align: center;
    width: 100%;
}

/* Mobile View with Accordion */
@media (max-width: 768px) {

    .footer-container {
        flex-direction: column;
    }

    .footer-right {
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }

    .footer-accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }

    .footer-accordion-content.active {
        max-height: 200px;
        /* Adjust based on content */
        transition: max-height 0.3s ease;
    }

    .footer-accordion-title {
        cursor: pointer;
        padding: 10px 0px;
        margin-bottom: 0px !important;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-social,
    .footer-language,
    .footer-links {
        justify-content: center;
        width: 100%;
        text-align: center;
    }

    .footer-social {
        margin-bottom: 20px;
    }

    .footer-links ul {
        justify-content: center;
    }

    .footer-links ul li {
        margin-bottom: 8px;
    }
}