.spinner {
  position: absolute;
  top: 45%;
  left: 47%;
  /* transform: translate(-50%, -50%); */

  width: 100px;
  height: 100px;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  animation: spinner-e04l1k 1s infinite linear;
  margin: 0;
  padding: 0;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.spinner::before {
  border-color: #474bff #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.spinner::after {
  margin: 8.9px;
}

@keyframes spinner-e04l1k {
  100% {
    transform: rotate(1turn);
  }
}


/* .loader {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
} */

.heading {
  /* color: #071952; */
  color: #071952;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  font-size: 2em;
}

.loading {
  display: flex;
  width: 15em;
  align-items: center;
  justify-content: center;
}

.load {
  width: 150px;
  height: 7px;
  /* background-color: #97FEED; */
  background-color: #16beb9;
  animation: 1s move_5011 infinite;
  border-radius: 5px;
  margin: 0.1em;
}

.load:nth-child(1) {
  animation-delay: 0.2s;
}

.load:nth-child(2) {
  animation-delay: 0.4s;
}

.load:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes move_5011 {
  0% {
    width: 2em;
  }

  25% {
    width: 1.5em;
  }

  50% {
    width: 6em;
  }

  100% {
    width: 2em;
  }
}


/* Full-screen overlay */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

/* Circular loader */
.circular-loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spin animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
