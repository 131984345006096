.header-block {
    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 1003;
    width: 100%;
    transform: translateY(0px);
    transition: transform 0.3s;
    border-bottom: 1px solid rgb(231, 237, 246);
    background-color: white;
}

.header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 14px 10%;
}

.header-wrapper a {
    color: black;
    text-decoration: none;
    font-weight: bold;
}


.header-logo {
    width: 400px;
}

.logo-wrapper {
    font-size: 34px;
}

.item-list {
    display: flex;
    width: 75%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}

.item-list li {
    padding: 0px 20px;
}

.category-item {
    margin-right: 10px;
}


.search-bar-wrapper {
    width: 50%;
    margin-right: 15%;
}

.search-bar {
    display: flex;
    align-items: center;
}

.search-bar input {
    border: 2px solid rgb(231, 237, 246);
    padding: 2px 10px;
    border-radius: 6px 0px 0px 6px;
    outline: none;
    font-size: 16px;
    min-width: 200px;
    width: 100%;
    height: 37px;
}

.search-button {
    padding: 5px 20px;
    max-width: fit-content;
    width: 100%;
    text-align: center;
    background-color: var(--primary-button);
    border-color: var(--primary-button);
    color: var(--primary-button-text);
    height: 37px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;
}

.signup-button {
    width: fit-content;
    height: 100%;
    padding: 10px 20px;
    background-color: var(--primary-button);
    border-color: var(--primary-button);
    border-radius: 6px;
    color: white;

}

.auth-items {
    display: flex;
    width: 25%;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}

/* Main container for the dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Style for the dropdown toggle button */
.dropdown-toggle {
    cursor: pointer;
    font-weight: bold;
}

/* Style for the dropdown menu (flex layout for 2 columns) */
.dropdown-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 0px;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
}

/* Show dropdown menu on hover */
.dropdown:hover .dropdown-menu {
    display: flex;
}

/* Left column (categories and subcategories) */
.dropdown-column-left {
    width: 60%;
    padding: 10px 0px;
    min-width: 300px;
    border-right: 1px solid #ddd;
}

/* Category items */
.dropdown-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

/* Submenu for subcategories */
.dropdown-submenu {
    display: none;
    background-color: #f8f9fa;
    border-radius: 4px;
    padding-left: 20px;
    position: absolute;
    left: 100%;
    top: 0;
    min-width: 200px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.dropdown-item:hover .dropdown-submenu {
    display: block;
}

/* Right column (links section) */
.dropdown-column-right {
    width: 40%;
    padding-top: 10px;
    min-width: 200px;
    background-color: #f6f7f8;
}

/* Styling for additional links */
.dropdown-link {
    font-size: 16px;
    color: #007bff;
    cursor: pointer;
}

.dropdown-link:hover {
    color: #0056b3;
}

/* Divider for submenus */
.dropdown-divider {
    margin: 10px 0;
    border-top: 1px solid #ddd;
}

/* Footer section styling */
.dropdown-footer {
    padding-top: 15px;
    font-size: 14px;
    color: #666;
    display: flex;
    align-items: center;
}

.dropdown-footer i {
    margin-right: 10px;
}

.sideBar {
    display: none;
}

.min-w-300 {
    min-width: 300px !important;
}

@media (max-width: 1024px) {

    .header-wrapper {
        padding: 10px 5%;
    }

    .header-logo {
        width: auto;
    }

    .logo-wrapper {
        font-size: 24px;
        width: 195px;
    }

    .item-list li {
        padding: 0px 15px;
    }

    .search-bar input {
        width: 180px;
    }

}

@media (max-width: 991px) {

    .header-wrapper {
        padding: 20px 5%;
    }

    .item-list {
        display: none;
    }

    .logo-wrapper {
        font-size: 16px;
        width: 195px;
        font-weight: bold;
    }

    .auth-items {
        display: none;
    }

    .auth-items-mob {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 0px;
        list-style-type: none;
        padding-left: 0px;
    }

    .item-list-mob {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        padding-left: 0px;
        list-style-type: none;
    }


    .item-list-mob .category-item {
        margin-right: 10px;
    }

    .menu-icon {
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 24px;
    }

    .menu-icon div {
        width: 30px;
        height: 5px;
        background-color: black;
        margin: 3px 0;
    }

    .sideBar {
        width: 0px;
        display: block;
        height: 100vh;
        position: fixed;
        z-index: 1;
        top: 0px;
        right: 0;
        overflow-x: hidden;
        transition: 0.5s;
        background-color: white;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    .closebtn {
        font-size: 35px;
        display: flex;
        justify-content: end;
    }


    .item-list-mob {
        align-items: start;
    }

    .item-list-mob li {
        padding: 10px 20px 10px 20px !important;
        border-bottom: 1px solid #e0e0e0;

    }

    .header-wrapper a {
        font-weight: normal !important;
    }

    .search-bar-wrapper {
        width: auto;
        margin-right: 0;
        display: flex;
    }

    .search-button {
        border-radius: 50%;
        background-color: white;
        color: var(--primary-button-text);
        padding: 0px 20px;
        font-size: 20px;
        height: fit-content;
    }

    .auth-items-mob li {
        padding: 10px 20px 10px 20px !important;
        border-bottom: 1px solid #e0e0e0;
    }

    .collapse-content {
        list-style-type: none !important;
        padding-left: 0 !important;
    }

    .collapse-content li {
        margin-bottom: 10px !important;
        color: black !important;
        text-decoration: none !important;
        border: 0px !important;
        padding: 0px !important;
    }

    #category-menu span {
        font-size: 16px;
        color: #333;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .collapse-content span {
        font-size: 16px;
        padding: 10px;
        color: #333;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #category-menu i {
        transition: transform 0.3s ease;
    }

    #category-menu i.up {
        transform: rotate(180deg);
    }

    #category-menu i.down {
        transform: rotate(0deg);
    }

    #category-menu ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    #category-menu li {
        padding: 0;
        margin: 10px 0;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #f9f9f9;
    }

    #category-menu li ul {
        padding-left: 15px;
        border-top: 1px solid #ddd;
    }

    #category-menu li ul li {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin: 5px 15px !important;
    }

    #category-menu li a {
        text-decoration: none;
        color: #333;
        font-size: 14px;
        cursor: pointer;
    }

    .signup-button {
        height: 100%;
        padding: 0px;
        background-color: transparent;
        border-radius: 6px;
        color: black;
    }

    .collapse-content {
        display: none;
    }

    .show {
        display: block;
    }
}