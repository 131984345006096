/* .calls {
 
  margin-top: 0.5rem;
  width: 80%;
  padding: 10px;
} */
.calls .calls-main {
  margin-top: 24px;
}
.calls .bookings .tab-content .calls-card {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  margin-bottom: 20px;
}
.calls .calls-main .calls-card .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.calls .calls-main .calls-card .calls-header {
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid rgba(231, 234, 239, 0.5);

  padding: 12px 24px;
}
.calls .calls-main .calls-card .calls-content {
  padding: 16px 20px 44px;
}
.calls .calls-main .calls-card .calls-footer {
  border-top: 1px solid #e8e8e8;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 20px;
}
