.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.video-call {
  background-color: #f1f6fc;
  height: 100%;
  min-height: 100vh;
  padding: 20px;
}

.service {
  padding: 0px 20px 20px;
}

.service-all-information {
  padding-left: 25px;
  box-shadow: 0px 2px 13px 6px rgba(0, 0, 0, 0.1);
}

.common-nav {
  padding: 24px 0 18px;
}

.title-section {
  margin-top: 14px;
  background-color: #2aadde;
}

.service-user-name {
  font-size: 32px;
  font-weight: bold;
}

.service-text {
  color: rgb(161 161 161) !important;
  border-bottom: 1px solid rgb(161 161 161 /30%);
  width: 60%;
}

.service-detail-section .service-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  padding: 20px 0px;
  margin-bottom: 0;
}

.service-duration {
  border: 3px solid #84f0ad;
  background-color: #c9fcdc6e !important;
  border-radius: 6px;
  min-width: 100px;
}

.service-charge {
  border: 3px solid #f2ff00;
  background-color: #f4ff213d !important;
  border-radius: 6px;
  min-width: 100px;
}

.feedback-container {
  margin-top: 5rem;
  padding-bottom: 32px;
}

.feedback-title {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.slider-section {
  /* max-height: 250px; */
  /* overflow-x: hidden;
  overflow-y: scroll; */
  scrollbar-width: none;
  /* Firefox */
}

.slider-section::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari */
}


.slots-header {
  height: 80px;
  padding: 24px 0 18px;
}

.slots-header>.heading {
  margin-left: 10px;
  font-size: 32px;
}

.calendar-container {
  margin-left: 0px;
  margin-top: 30px;
}

/*=================Calendar CSS ===================*/
.css-u0soqy-MuiPickerStaticWrapper-root {
  box-shadow: none !important;
}

.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  padding: 0 !important;
}

.MuiTypography-overline {
  display: none;
}

.css-1beylcr {
  display: none !important;
}

.css-14b29qc {
  display: none !important;
}

.css-1bqwt78 {
  justify-content: center !important;
  align-items: center !important;
}

.css-jhsfre-MuiGrid-root-MuiPickersToolbar-content {
  background: white;
  padding: 1rem;
  justify-content: center !important;
}

.css-4l7j15 {
  min-width: 0 !important;
  border-radius: 14px;
}

.calender-inner {
  min-width: 550px;
}

.css-1n2mv2k {
  gap: 1rem;
}

.css-mvmu1r {
  gap: 1rem;
}

.css-epd502 {
  min-width: 400px;
}

.css-1brzq0m {
  min-width: 300px !important;
  width: 100% !important;
}

.css-i6bazn {
  min-width: 400px !important;
  width: 100% !important;
}

.css-1qbc4x0-MuiYearPicker-root {
  scrollbar-width: none;
}

.css-1qbc4x0-MuiYearPicker-root::-webkit-scrollbar {
  display: none;
}

.css-hlj6pa-MuiDialogActions-root {
  display: none !important;
}

.css-1hbyad5-MuiTypography-root {
  display: none !important;
}

.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
  display: none !important;
}


.Mui-selected {
  border: 3px solid #f2ff00 !important;
  color: black !important;
  background-color: #f4ff213d !important;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  border: 3px solid #f2ff00;
  color: black !important;
  background-color: #f4ff213d !important;
}

.timezone {
  width: 100%;
  display: flex;
  justify-content: center;
}

/*selectedTime background changes*/
.btn-selected-time {
  background-color: #d3d4d5;
}

.selected-time {
  border: 3px solid #84f0ad;
  color: #00ae42 !important;
  background-color: white !important;
  border-radius: 6px;
  padding: 2px 12px;
  font-weight: bolder;
  font-size: 14px;
  height: 38px;
  min-width: 95px;
}

.time-slot-div {
  background-color: #fff !important;
  border-radius: 6px;
  border: 3px solid #fff !important;
  padding: 6px 12px;
  height: 38px;
  border: none;
  font-size: 14px;
  min-width: 95px;
}

.timeslot-container {
  max-height: 145px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.timeslot-container::-webkit-scrollbar {
  display: none;
}

.user-review {
  position: relative;
  margin: 10px 0px;
}

.user-review:first-child {
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}

.ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Show max 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: normal;
  /* Allow multi-line wrapping */
  width: 100%;
}

.custom-tooltip {
  position: absolute;
  background: #f1f6fc;
  color: var(--theme-color);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  /* white-space: nowrap; */
  top: 0%;
  /* Show below text */
  left: 5%;
  transform: translateY(5px);
  z-index: 9999;
  display: block;
  font-size: 16px;
  max-width: 350px;
  width: 100%;
}

.confirm-btn {
  min-width: 300px;
}

.review-container {
  text-align: left;
  margin: auto;
  position: relative;
  background: white;
  padding: 20px 30px;
  border-radius: 10px;
}

.review-card {
  flex: 1;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.stars {
  color: gold;
}

.review-wrapper {
  min-height: 180px;
}

/* Position arrows in top-right corner */
.controls {
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  gap: 10px;
}

.arrow {
  background: none;
  border: 2px solid black;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.arrow:disabled {
  opacity: 0.4;
}

.see-all {
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  border: none;
}

.time-selection-container {
  display: flex;
  justify-content: right;
}

.timezone-select {
  width: 75%;
}

@media (max-width: 768px) {
  .video-call {
    padding: 0px;
  }

  .slots-header>.heading {
    margin-left: 0px;
    font-size: 18px;
  }

  .slots-header {
    padding: 12px 0 0px;
    height: fit-content;
  }

  .timezone-select {
    width: 100%;
  }

  .calender-inner {
    min-width: 100%;
  }

  .css-epd502 {
    min-width: 300px;
  }

  .css-1brzq0m {
    min-width: 300px !important;
    width: 100% !important;
  }

  .css-i6bazn {
    min-width: 300px !important;
    width: 100% !important;
  }

  .css-1n2mv2k,
  .css-mvmu1r {
    gap: 0.2rem;
  }

  .time-slot-div {
    height: 42px;
    min-width: 99px;
    font-size: 15px;
  }

  .selected-time {
    height: 42px;
    min-width: 99px;
    font-size: 15px;
  }
}