.btn-custom {
    border-color: grey;
    color: black;
    border-radius: 0.3rem !important;
  }
  .type-button {
    width: 8rem;
    height: 5rem;
  }
  .btn-custom:hover {
    border-color: darkgrey;
    transition: 0.3s;
  }
  .activeButton {
    border-color: skyblue;
  }
  .activeButton:hover {
    border-color: skyblue;
  }
  .ServiceForm {
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 5px;
    padding-right: 5px;
  }