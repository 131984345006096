*{
  color: #000000;
}
.text-query {
  width: 100%;
  height: 100vh;
  /* background-color: bisque; */
  display: flex;
  overflow: hidden;
}
.text-query .service-details {
  width: 30%;
  background-color: #3c9ae2;

}
.text-query .text-query-container {
  background: white;
  width: 45%;
  max-width: 1000px;
}
.text-query .text-query-container .form-group {
  padding-top: 10px;
  border: 1px solid #ebeaeb;
  border-radius: 10px;
  padding: 35px;
  
}
.text-query .text-query-container{
  width: 70%;

}
.text-query-container .text-query-service-information .container{
  width: 39%;
padding: 4.5rem 0;
}

.text-query-container .text-query-service-information .container .form-group{
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 35px;
}

.text-query-container .text-query-service-information .container .form-group .username-field{
  margin-bottom: 1rem;
}

.text-query-container .text-query-service-information .container .form-group .sumbit-button .btn .btn-lg .btn-primary{
  margin-top: 1rem;
}

.text-query-container .text-query-service-information .container .form-group .sumbit-button{
  padding: 0 0 0 58%;
  margin-top: 1rem;
}

.text-query .service-details .service-body{
  padding: 10rem 0;
}

.text-query .service-details .service-body .service-details-title{
  font-size: 1.5rem;
}

.text-query .service-details .service-body .combine .description-about-question{
  font-weight: normal;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 10px 0 0 10px;
  background: #62a9df;
  /* border: 0.5px solid #e3e8ed; */
  /* padding: 1rem 2rem 1rem 1rem;
  border-radius: 10px 0 0 10px; */
}

.text-query .service-details .service-body .combine .other-information{
  margin-left: .3rem;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 0 10px 10px 0;
  background: #62a9df;
}

.text-query .service-details .service-body .combine{
  /* border: 0.5px solid #e3e8ed; */
  display: flex;
  margin: 0 1rem 0 2.2rem;
  padding: 1rem;
}

.martop{
  margin-top: 1rem;
}
