.payment-body {
  margin-left: 50px;
  margin-top: 10px;
}
.payment-body .rounded-table {
  border-top: 2px solid #dbd1fc;

  border-radius: 10px; /* Adjust the value as per your preference */
  overflow: hidden; /* Ensures the rounded corners are visible */
}

.payment-cards .payment-nav-first {
  background: #ebf3fd;
}
.payment-cards .payment-nav-second {
  background: #f4dedc;
}
.payment-cards .payment-nav-third {
  background: #dbd1fc;
}
.payment-cards .balance-cards {
  border-radius: 12px;
  padding: 32px 20px 32px;
}
