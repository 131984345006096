.queries .header-buttons .selected-queries-header {
  background-color: #80b9ebb2;
}
.queries .header-buttons .btn-outline-secondary {
  --bs-btn-hover-bg: #80b9ebb2;
}
.query-main .skeleton-text {
  width: 70%;
  height: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
}
.query-main .skeleton-text:first-child {
  width: 35%;
}
.queries .query-main {
  margin-left: 20px;
}
.queries .query-main .query-sub-main {
  margin-left: 30px;
}
.all-query-section-list {
  padding: 0;
}
.query-sub-main .all-query-section-list .query-dropdown {
  justify-content: center;
}
.query-sub-main .all-query-section-list .query-list-items .query-item {
  margin-top: 15px;
  padding: 5px;
  background-color: #f1f7fd;
  border-top: 0.2px solid lightgray;
}
.query-list-items .query-item .query-details .query-header {
  margin-bottom: 5px !important;
  padding: 0;
}
.query-list-items .query-item .query-details .query-description .description {
  margin-bottom: 2px !important;
}
.main-query-section-box {
  padding: 0;
  border-left: 1px solid lightgrey;
}
.main-query-section-box .query-box {
  margin-left: 30px;
  width: 75%;
}
.query-box .userInformation {
  margin-left: 20px;
}
.query-box .query-box-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.query-box .query-box-title {
  margin-top: 20px;
}
