.termConditon_section h1,
.termConditon_section h2 {
    color: #fff;
}

.termConditon_section h1 {
    font-size: 2.5em;
    color: #fff;
}

.termConditon_section h4 {
    margin-top: 30px;
    color: #fff;
}
.termConditon_section h5 {
    margin-top: 20px;
    color: #fff;
}

.termConditon_section p {
    margin-bottom: 0px;
    color: #fff;
}

.termConditon_section ul li {
    color: #fff;
}

.termConditon_section b {
    font-weight: bold;
    color: #fff;
}

.termConditon_section ul {
    margin-left: 20px;
    color: #fff;
}