.skeleton-table {
  width: 100%;
  max-width: 600px; /*Adjust as needed */
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.skeleton-row {
  height: 20px;
  margin-bottom: 10px;
  background-color: #e0e0e0;
  animation: loading 1s infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
